import React, { useContext, useState } from 'react';
import Waiting from '../../components/Waiting';
import { ScreenEnum } from '../../enums';
import AppStateContext from '../../AppStateProvider/context';
import Progress from '../../components/Progress';
import { useTranslation } from 'react-i18next';
import addParam from '../../utils/addParam';
import DeviceTypeSelection from '../../components/DeviceTypeSelection';
import './styles.scss';
import WarningOverlay from '../../components/WarningOverlay';

export default function HardwareCheck(props: {
	setScreen: (next: ScreenEnum) => void;
	backBtn: Function;
	cancelBtn: JSX.Element;
	deviceType: string | null;
}) {
	const { state } = useContext(AppStateContext);
	const { t } = useTranslation();
	const [deviceType, setDeviceType] = useState<string | null>(
		props.deviceType
	);
	const [hardwareCheckFailed, setHardwareCheckFailed] =
		useState<boolean>(false);
	const next = state.apk ? ScreenEnum.softwareCheck : ScreenEnum.selectBrand;

	async function checkBrowser() {
		for (let i = 1; i <= 7; i++) {
			try {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const [_, response] = await Promise.all([
					new Promise(resolve => setTimeout(resolve, 2000)),
					fetch(`https://dev${i}.did.sb12.de/login`, {
						headers: {
							'Cache-Control': 'max-age=0 no-cache',
							'Service-Worker': 'false',
						},
					}),
				]);

				if (response.status === 200) {
					props.setScreen(next);
					return;
				}
			} catch (e) {
				console.log(e);
			}
		}

		setHardwareCheckFailed(true);
	}

	if (state.apk) {
		return (
			<>
				<div className='top less-padding'>
					<div className='hardware-check'>
						{props.backBtn(
							state.apk
								? ScreenEnum.setSecond
								: ScreenEnum.welcome
						)}
						<h1>{t('HardwareSelectionHeadline')}</h1>
						<p className='p1'>{t('HardwareSelectionText')}</p>
						<Progress
							screen={ScreenEnum.hardwareCheck}
							setScreen={props.setScreen}
						/>
						<DeviceTypeSelection
							deviceType={deviceType}
							setDeviceType={setDeviceType}
						/>
					</div>
				</div>
				<div className='buttons'>
					<button
						className='white'
						onClick={() => {
							addParam('device-type', deviceType, true);
							props.setScreen(next);
						}}
						disabled={!deviceType}
					>
						{t('HardwareCheckConfirm')}
					</button>
					<button
						className='black'
						onClick={() => props.setScreen(next)}
					>
						{t('ButtonSkip')}
					</button>
				</div>
			</>
		);
	} else {
		checkBrowser();
	}

	return (
		<>
			<div className='top'>
				<div className='hardware-check'>
					{props.backBtn(
						state.apk ? ScreenEnum.setSecond : ScreenEnum.welcome
					)}
					<Waiting
						screen={ScreenEnum.hardwareCheck}
						setScreen={props.setScreen}
					/>
					{hardwareCheckFailed && (
						<WarningOverlay
							headline={'Warning'}
							texts={['WarningTextHardwareCheckFailed']}
							btnFunction={() =>
								props.setScreen(ScreenEnum.welcome)
							}
							okBtn={true}
						/>
					)}
				</div>
			</div>
		</>
	);
}
