import React, { useContext, useEffect, useRef, useState } from 'react';
import './styles.scss';
import Progress from '../../components/Progress';
import { SetSecondProps } from './interfaces';
import { useTranslation } from 'react-i18next';
import { ScreenEnum } from '../../enums';
import RadioButtons from '../../components/RadioButtons';
import Option from '../../components/Option';
import addParam from '../../utils/addParam';
import { deviceTypeList } from '../../utils/deviceTypeList';
import AppStateContext from '../../AppStateProvider/context';

// Extend the Window interface to include getScreens method
declare global {
	interface Window {
		getScreens?: () => Promise<Screen[]>;
	}
}

interface Screen {
	availLeft: number;
	availTop: number;
}

export default function SetSecond(props: SetSecondProps) {
	const { state, dispatch } = useContext(AppStateContext);
	const { t } = useTranslation();
	const [orientation, setOrientation] = useState<string>('');
	const [showSecond, setShowSecond] = useState<boolean>(props.showSecond);
	const chromebox =
		new URLSearchParams(window.location.search).get('chromebox') === 'true';

	const url = new URL(`${window.location.origin}/index.html`);
	url.searchParams.set('label', 'true');
	if (orientation) url.searchParams.set('orientation', orientation);

	const popupRef = useRef<Window | null>(null);
	const openWindow = async () => {
		try {
			if ('getScreenDetails' in window) {
				const screens = await (window as any).getScreenDetails();

				if (screens.screens.length > 1) {
					const secondScreen = screens.screens[1];
					const width = secondScreen.width;
					const height = secondScreen.height;
					const left = secondScreen.left;
					const top = secondScreen.top;

					popupRef.current = window.open(
						url,
						'label',
						`width=${width},height=${height},left=${left},top=${top}`
					);
				} else {
					alert('Only one screen detected.');
				}
			} else {
				alert('Window Management API not supported.');
			}
		} catch (error) {
			console.error('Error using Window Management API:', error);
		}
	};

	const redirectAudi = () => {
		if (popupRef.current) {
			popupRef.current.location.href = `${window.location.origin}/${state.brand}/index.html?label=true`;
			popupRef.current.location.replace(
				`${window.location.origin}/audi/index.html?label=true`
			);
		}
	};

	const redirectVw = () => {
		if (popupRef.current) {
			popupRef.current.location.href = `${window.location.origin}/${state.brand}/index.html?label=true`;
			popupRef.current.location.replace(
				`${window.location.origin}/vw/index.html?label=true`
			);
		}
	};

	window.addEventListener('redirect-label-audi', redirectAudi);
	window.addEventListener('redirect-label-vw', redirectVw);

	useEffect(() => {
		if (chromebox) {
			if (showSecond && (!popupRef.current || popupRef.current?.closed)) {
				openWindow();
			} else {
				popupRef.current?.close();
				popupRef.current = null;
			}
		}
	}, [showSecond]);

	async function handleClick(value: boolean | string) {
		if (value === true) {
			addParam('orientation', orientation, true);
			setShowSecond(value);
		} else if (value === false) {
			addParam('orientation', null, true);
			setShowSecond(value);
		} else {
			addParam('orientation', value, true);
			setOrientation(value);
		}
	}

	return (
		<>
			<div className='top'>
				<div className='set-second'>
					{props.backBtn(ScreenEnum.welcome)}
					<h1>{t('SetSecondHeadline')}</h1>
					<p className='p1'>{t('SetSecondText')}</p>
					<Progress
						screen={ScreenEnum.setSecond}
						setScreen={props.setScreen}
					/>
					<Option
						selected={showSecond}
						text={t('SetSecondShow')}
						onClick={() => handleClick(!showSecond)}
						className='show-second'
						checkMark={true}
					/>
					{!chromebox && (
						<RadioButtons
							headline={t('SetSecondRotation')}
							disabled={!showSecond}
							options={[
								{
									selected: orientation === 'default',
									text: '0°',
									value: 'default',
								},
								{
									selected: orientation === 'right',
									text: '90°',
									value: 'right',
								},
								{
									selected: orientation === 'down',
									text: '180°',
									value: 'down',
								},
								{
									selected: orientation === 'left',
									text: '270°',
									value: 'left',
								},
							]}
							onClick={(value: string) => handleClick(value)}
						/>
					)}
				</div>
			</div>
			<div className='buttons'>
				{!chromebox ? (
					<>
						<button
							className='white'
							onClick={() =>
								props.setScreen(
									deviceTypeList.length > 1
										? ScreenEnum.hardwareCheck
										: ScreenEnum.softwareCheck
								)
							}
							disabled={!showSecond || orientation === ''}
						>
							{t('SetSecondConfirm')}
						</button>

						<button
							className='black'
							onClick={() =>
								props.setScreen(
									deviceTypeList.length > 1
										? ScreenEnum.hardwareCheck
										: ScreenEnum.softwareCheck
								)
							}
						>
							{t('ButtonSkip')}
						</button>
					</>
				) : (
					<button
						className='white'
						onClick={() =>
							props.setScreen(
								deviceTypeList.length > 1
									? ScreenEnum.hardwareCheck
									: ScreenEnum.softwareCheck
							)
						}
					>
						{t('ButtonConfirm')}
					</button>
				)}
			</div>
		</>
	);
}
